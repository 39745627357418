import React from 'react';
import { MuSvg } from '../MuSvg';
import * as Icons from '../../../assets/icons/index';
import { SvgrComponent } from '../../Types/common';

type Props = {
    className?: string;
    svg?: SvgrComponent;
    svgName?: keyof typeof Icons;
};

const MuIcon: React.FC<Props> = ({ className, svg, svgName }: Props) => {
    let component = svg;
    if (!component && svgName) {
        component = Icons[svgName];
    }

    if (!component) return null;

    return <MuSvg className={className} SvgComp={component} />;
};

export default MuIcon;
