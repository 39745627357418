/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    QuestionUpdatePayload,
    updateQuestion,
    addQuestion,
    deleteQuestion,
    Question,
    QuestionnaireUpdatePayload,
    updateQuestionnaire,
    Questionnaire,
    useCurrentOrganizationId,
    reorderQuestion,
    translateQuestionnaire,
    runQuestionnaireTriggers,
} from 'mushin-redux-store';
import i18n from 'i18next';
import Delta from 'quill-delta';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import MuButton from '../../Components/MuButton';
import QuestionEdition from './QuestionEdition';
import { openModal } from '../../Redux/reducers/modalsSlice';
import { formatQuestionTitle, hasStartedQuestionnaire } from '../../Helpers/questionnaires';
import MuIcon from '../../Components/MuIcon';
import QuillRichInput from '../../Components/QuillRichInput/QuillRichInput';
import { useQuillInputWithDelay } from '../../Helpers/FormsHelpers/hooks';
import AccessTypeSelect from './Components/AccessTypeSelect';
import QuestionnairePublicLink from './Components/QuestionnairePublicLink';
import { DnDItemTypes } from '../../Helpers/dnd';
import { classWithModifiers } from '../../Helpers/css';
import QuestionnairePreview from '../QuestionnairePreview/QuestionnairePreview';
import { AddModalImages } from '../AppModals/Components/AddImagesModal/AddIdeaModal';
import { uploadFile } from '../../Helpers/image';
import { useAppDispatch } from '../../Helpers/hooks';
import EditQuestionImage from './Components/EditQuestionImage';
import MuButtonWithLoader from '../../Components/MuButtonWithLoader';
import { isQuestionnaireTranslated } from '../../Helpers/translate';
import LanguageSelect from './Components/LanguageSelect';
import { useQuestionnaireEditorContext } from './Components/QuestionnaireEditorContext';

type Props = {
    questionnaire: Questionnaire;
};

const QuestionnaireEditor: React.FC<Props> = ({ questionnaire }) => {
    const { locale } = useQuestionnaireEditorContext();
    const dispatch = useAppDispatch();
    const [showPreview, setShowPreview] = useState(false);
    const [currentOrganizationId] = useCurrentOrganizationId();

    const editQuestionnaire = async (questionnairePayload: QuestionnaireUpdatePayload) => {
        dispatch(updateQuestionnaire(questionnaire.id, { ...questionnairePayload, locale }));
    };

    const [title, setTitle] = useState(questionnaire.title);
    const [description, setDescription, resetDescription] = useQuillInputWithDelay(
        (value) => editQuestionnaire({ desc: value }),
        new Delta(questionnaire.desc?.ops || []),
    );

    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        resetDescription(new Delta(questionnaire.desc?.ops), false);
    }, [questionnaire.desc, resetDescription]);

    useEffect(() => {
        if (locale === 'current') {
            setTitle(questionnaire.title);
            setDescription(new Delta(questionnaire.desc?.ops || []), true, true);
        } else {
            setTitle(questionnaire?.titles?.translations?.[locale]?.value || '');
            setDescription(
                new Delta(questionnaire?.descriptions?.translations?.[locale]?.value?.ops || []),
                true,
                true,
            );
        }
    }, [locale]);

    useEffect(() => {
        if (locale === 'current') return;
        setTitle(questionnaire.titles.translations[locale]?.value || '');
        setDescription(new Delta(questionnaire.descriptions.translations[locale]?.value?.ops || []), true, true);
    }, [questionnaire]);

    if (!questionnaire || !currentOrganizationId) return null;

    const editQuestion = (id: string, questionPayload: QuestionUpdatePayload) => {
        dispatch(updateQuestion(questionnaire.id, id, { ...questionPayload, locale }));
    };

    const createQuestion = (index: number = questionnaire.questions.length + 1, questionToDuplicate?: Question) => {
        dispatch(
            addQuestion(
                questionnaire.id,
                questionToDuplicate || {
                    type: 'free',
                    freeAnswerTypes: ['text'],
                    label: formatQuestionTitle(i18n.t<string>('questionEdition.newDefaultQuestion')),
                    mandatory: true,
                },
                index,
            ),
        );
    };

    const removeQuestion = (questionId: string) => {
        dispatch(deleteQuestion(questionnaire.id, questionId));
    };

    const hasStarted = hasStartedQuestionnaire(questionnaire);

    const handleReorderQuestions = ({ source, destination }: DropResult) => {
        setIsDragging(false);
        if (!destination || source.index === destination.index) return;
        dispatch(reorderQuestion(questionnaire, source.index, destination.index));
    };

    const translated = isQuestionnaireTranslated(questionnaire);

    return (
        <main className={classWithModifiers('mu-edit-questionnaire', { 'can-edit': !hasStarted })}>
            {showPreview && (
                <div className="mu-edit-questionnaire__preview">
                    <button
                        type="button"
                        className="mu-edit-questionnaire__preview-close"
                        onClick={() => setShowPreview(false)}
                    >
                        <MuIcon svgName="Close" />
                    </button>
                    <QuestionnairePreview questionnaire={questionnaire} applyConditional />
                </div>
            )}
            <div className="mu-edit-questionnaire__actions-wrapper">
                <div className="mu-edit-questionnaire__actions">
                    <MuButton
                        icon="Show"
                        className="mu-edit-questionnaire__action-button"
                        label={i18n.t('global.preview')}
                        handleClick={() => setShowPreview(true)}
                    />
                    {!!questionnaire.locales?.length && (
                        <MuButtonWithLoader
                            icon={translated ? 'Check' : 'Edit'}
                            className="mu-edit-questionnaire__action-button"
                            classModifiers={translated ? 'valid' : 'important'}
                            label={i18n.t('global.translate')}
                            handleClick={() => dispatch(translateQuestionnaire(questionnaire.id))}
                        />
                    )}
                    {!hasStarted && (
                        <MuButton
                            icon="Plus"
                            className="mu-edit-questionnaire__action-button"
                            label={i18n.t('questionnaires.add')}
                            handleClick={() => createQuestion()}
                        />
                    )}
                    {hasStarted &&
                        questionnaire.questions.some(
                            (question) => 'criterionId' in question && !!question.criterionId,
                        ) && (
                            <MuButtonWithLoader
                                icon="ConvertShape2"
                                className="mu-edit-questionnaire__action-button"
                                label={i18n.t('questionnaires.runTriggers')}
                                handleClick={() => dispatch(runQuestionnaireTriggers(questionnaire.id))}
                            />
                        )}
                    {!hasStarted && (
                        <MuButton
                            icon="Share"
                            classModifiers="important"
                            className="mu-edit-questionnaire__action-button"
                            label={i18n.t('global.publish')}
                            handleClick={() => {
                                dispatch(
                                    openModal('PublishModal', {
                                        onPublish: ({ start_date, end_date }) =>
                                            dispatch(
                                                updateQuestionnaire(questionnaire.id, {
                                                    start_date,
                                                    end_date,
                                                }),
                                            ),
                                        labelKey: 'questionnaires',
                                    }),
                                );
                            }}
                        />
                    )}
                </div>
            </div>
            <header className="mu-edit-questionnaire__header">
                <h1 className="mu-edit-questionnaire__title">
                    <input
                        type="text"
                        placeholder={i18n.t<string>('questionnaires.title')}
                        className="mu-edit-questionnaire__title-input mu-text-answer"
                        value={title}
                        onChange={(e) => setTitle(e.currentTarget.value)}
                        onBlur={() => editQuestionnaire({ title })}
                    />
                    {questionnaire.image_url ? (
                        <EditQuestionImage
                            className="mu-question-editor__image"
                            imageUrl={questionnaire.image_url}
                            updateQuestion={() => editQuestionnaire({ image_url: null })}
                        />
                    ) : (
                        <button
                            type="button"
                            className="mu-question-editor__add-image"
                            onClick={() =>
                                dispatch(
                                    openModal('AddIdeaModal', {
                                        multipleSelection: false,
                                        textNotes: false,
                                        addImages: async (addModalImages: AddModalImages) => {
                                            if (addModalImages.notes.length) {
                                                const note = addModalImages.notes[0];
                                                if (note.type !== 'TEXT')
                                                    await editQuestionnaire({ image_url: note.image_src.original });
                                            }
                                            if (addModalImages.images.length) {
                                                await editQuestionnaire({
                                                    image_url: await dispatch(
                                                        uploadFile('new_image', addModalImages.images[0]),
                                                    ),
                                                });
                                            }
                                        },
                                    }),
                                )
                            }
                        >
                            <MuIcon svgName="GalleryAdd" />
                        </button>
                    )}
                </h1>
                <QuillRichInput
                    value={description}
                    setValue={(e) => setDescription(e)}
                    placeholder={i18n.t<string>('questionnaires.desc')}
                    theme="snow"
                    className="mu-textarea-rich-input mu-edit-questionnaire__desc"
                    toolbar={[
                        ['bold', 'italic', 'strike', 'underline'], // toggled buttons
                        [{ header: 2 }], // custom button values
                        [{ list: 'bullet' }],
                        [{ header: [2, 3, false] }],
                    ]}
                    needTags={false}
                />
                <AccessTypeSelect questionnaire={questionnaire} />
                {!!questionnaire.locales?.length && <LanguageSelect questionnaire={questionnaire} />}
                <QuestionnairePublicLink questionnaire={questionnaire} />
            </header>
            <DragDropContext onDragEnd={handleReorderQuestions}>
                <Droppable droppableId={`${DnDItemTypes.QUESTIONNAIRE}-0`} type={DnDItemTypes.QUESTIONNAIRE}>
                    {(droppableProvided) => (
                        <section
                            ref={droppableProvided.innerRef}
                            className="mu-edit-questionnaire__forms"
                            {...droppableProvided.droppableProps}
                            onMouseUp={() => setIsDragging(false)}
                        >
                            {questionnaire.questions.map((question, index) => (
                                <Draggable
                                    key={question.id}
                                    draggableId={question.id}
                                    index={index}
                                    isDragDisabled={hasStarted}
                                >
                                    {(draggableProvided) => (
                                        <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                                            <QuestionEdition
                                                question={question}
                                                questionIndex={index}
                                                questionnaire={questionnaire}
                                                isDragging={isDragging}
                                                updateQuestion={(questionPayload) =>
                                                    editQuestion(question.id, questionPayload)
                                                }
                                                deleteQuestion={() => removeQuestion(question.id)}
                                                duplicateQuestion={(questionToDuplicate) =>
                                                    createQuestion(index + 1, questionToDuplicate)
                                                }
                                                createQuestion={() => createQuestion(index + 1)}
                                                disabled={hasStarted}
                                                dragHandleProps={draggableProvided.dragHandleProps}
                                                onDragStart={() => setIsDragging(true)}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                        </section>
                    )}
                </Droppable>
            </DragDropContext>
        </main>
    );
};

export default QuestionnaireEditor;
