import React, { useState, useEffect } from 'react';
import { useCriterion } from 'mushin-redux-store';
import i18n from 'i18next';
import DropdownMenu from '../../../Components/DropdownMenu/DropdownMenu';
import MuIcon from '../../../Components/MuIcon';
import MuButton from '../../../Components/MuButton';
import CheckboxRadio from '../../../Components/Form/CheckboxRadio';
import { displayValue, isChecked, setChecked } from '../../../Helpers/questionnaires';

type Props = {
    onSelectMembers: (members: string[]) => void;
    selectedCriterion?: string;
    selectedMembers?: string[];
    disabled?: boolean;
    toggleClassName?: string;
};

const CriterionFilterValuesSelection: React.FC<Props> = ({
    onSelectMembers,
    selectedCriterion,
    selectedMembers,
    disabled,
    toggleClassName = 'mu-question-editor__filter-icon',
}) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [newValue, setNewValue] = useState<string[] | undefined>(selectedMembers);

    const criterion = useCriterion(selectedCriterion);

    useEffect(() => {
        if (isMenuOpen) {
            setNewValue(selectedMembers);
        }
    }, [isMenuOpen, selectedMembers]);

    const onSelect = (members: string[] = []) => {
        onSelectMembers(members);
        setMenuOpen(false);
    };

    return (
        <>
            <DropdownMenu
                align="center"
                isOpen={isMenuOpen}
                setIsOpen={setMenuOpen}
                toggleClassName={toggleClassName}
                toggle={
                    <>
                        <MuIcon svgName="Stats" />
                        {!!selectedMembers?.length && (
                            <div className="mu-question-editor__filter-value">
                                {!disabled && (
                                    <button
                                        type="button"
                                        className="mu-question-editor__remove-filter-icon"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onSelect([]);
                                        }}
                                    >
                                        <MuIcon svgName="Close" />
                                    </button>
                                )}
                                {displayValue(criterion?.values || [], selectedMembers)}
                            </div>
                        )}
                    </>
                }
                disabled={disabled}
            >
                <div className="mu-filters-toolbar__inline-select">
                    <div className="mu-dropdown-menu__content--scroll">
                        {criterion?.values?.map((option) => {
                            const checked = newValue ? isChecked(option.slug, newValue) : false;
                            return (
                                <CheckboxRadio
                                    className="mu-dropdown-menu__button-item"
                                    key={option.slug}
                                    setChecked={() => {
                                        const selected = setChecked(
                                            !checked,
                                            option.slug,
                                            newValue,
                                            !criterion.multiple,
                                        );
                                        if (!selected) return setNewValue([]);
                                        setNewValue(Array.isArray(selected) ? selected : [selected]);
                                    }}
                                    checked={checked}
                                    label={option.label}
                                    uniqueSelection={!criterion.multiple}
                                />
                            );
                        })}
                    </div>
                    <MuButton
                        className="mu-filters-toolbar__panel-submit"
                        handleClick={() => onSelect(newValue)}
                        label={i18n.t('components.filters.dropdown.apply')}
                    />
                </div>
            </DropdownMenu>
        </>
    );
};

export default CriterionFilterValuesSelection;
